import { createGlobalStyle } from 'styled-components';
import { TTheme } from './theme.styles';

export interface ThemeProps {
  theme: TTheme;
}

export const GlobalStyle = createGlobalStyle`
  html, body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-family: ${(props: ThemeProps) => props.theme.fontFamily};
    font-size: 16px;

    h1, h2 {
      font-family: ${(props: ThemeProps) => props.theme.fontFamily};
      text-transform: unset;
    }

    & .pac-container {
      margin-top: 0.5rem;
      background-color: ${(props: ThemeProps) => props.theme.whiteColor};
      border-radius: 0.25rem;
      border: none;
      box-shadow: 0 0 0.25rem rgb(16 168 35 / 10%);
      padding: 0.5rem 0;
      &::after {
        content: none;
      }
      & .pac-item {
        padding: 0 0.5rem;
        cursor: pointer;
        border: none;
        &:hover {
          background-color: ${(props: ThemeProps) =>
            props.theme.inputBackgroundColor};
        }
        &-query {
          font-family: ${(props: ThemeProps) => props.theme.fontFamily};
          font-size: 1rem !important;
          & > .pac-matched {
            color: ${(props: ThemeProps) => props.theme.softGreenColor};
          }
        }
      }
    }
    & .aa-Panel {
      z-index: 2;
      max-width: 100% !important;
      min-width: 10.25rem;
      --aa-selected-color-rgb: 237,249,238;
      --aa-selected-color-alpha: 1;
      & .aa-Item {
        padding-left: 1.85rem;
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    font-family: ${(props: ThemeProps) => props.theme.fontFamily};
  }

  * {
    box-sizing: border-box;
  }

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox'] + label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    user-select: none;
  }

  input[type='checkbox'] + label::before {
    content: '';
    display: inline-block;
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1.5px solid #E5E5E5;
    border-radius: 0.25em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }

  input[type='checkbox']:checked + label::before {
    background-color: ${(props: ThemeProps) => props.theme.softGreenColor};
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  }
`;
