import Head from 'next/head';
import { AppProps } from 'next/app';
import { GlobalStyle } from '../styles/global';
import { ThemeProvider } from 'styled-components';
import { theme } from '@/styles/theme.styles';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import dynamic from 'next/dynamic';
import NextNProgress from 'nextjs-progressbar';
import isBlog from '@/utils/isBlog';
import { useRouter } from 'next/router';

const Header = dynamic(() => import(`@/components/Header/Header`));
const SubscribeSticky = dynamic(
  () => import(`@/containers/Blog/components/SubscribeSticky/SubscribeSticky`),
);
const Footer = dynamic(() => import(`@/components/Footer/Footer`));

const tagManagerArgs = {
  gtmId: process.env.TAG_MANAGER_ID || ``,
};

export default function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  const router = useRouter();
  const URL = `${process.env.URL}${router.asPath === `/` ? `` : router.asPath}`;

  return (
    <>
      <Head>
        <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
        <link rel="alternate" hrefLang="en" href={URL} />
        <link rel="canonical" href={URL} />
      </Head>
      <ThemeProvider theme={theme}>
        <NextNProgress
          color={theme.softGreenColor}
          height={2}
          options={{ showSpinner: false }}
        />
        <GlobalStyle />
        <Header currentCategory={pageProps.currentCategoryProps} />
        <Component {...pageProps} />
        {isBlog(router.pathname) && <SubscribeSticky />}
        <Footer />
      </ThemeProvider>
    </>
  );
}
