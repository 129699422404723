export const theme = {
  fontFamily: `'Ubuntu', sans-serif;`,
  blackColor: `#060e11`,
  lightGreenColor: `#10ab7c`,
  softGreenColor: `#10a823`,
  limeGreenColor: `#e0f4ee`,
  backgroundColor: `#f2f7f2`,
  whiteColor: `#ffffff`,
  lightGrayColor: `#e5e5e5`,
  grayColor: `#c4c4c4`,
  darkGrayColor: `#9f9f9f`,
  yellowColor: `#ffd02a`,
  turquoiseColor: `#13b8b6`,
  inputBackgroundColor: `#edf9ee`,
};

export type TTheme = typeof theme;
